import { cn } from "@/lib/utils.ts";
import type { ReactNode } from "react";
import React from "react";

export const ZipDealText = ({ children = null }: { children: ReactNode }) => (
	<div className={"text-zipgray-400 text-2xl font-normal"}>
		ZIP
		<span className={"font-bold"}>DEAL</span>
		{children}
	</div>
);
export const ZipDealTextSpan = ({ className = "", children = null }) => (
	<span
		className={cn({
			"text-zipgray-400 font-normal": true,
			[className]: true,
		})}
	>
		<span className={"font-bold"}>ZIP</span>
		DEAL
		{children}
	</span>
);
